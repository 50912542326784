import { VChip } from "vuetify/components";
import { PublishableStatus, type Publishable } from "../models";

export default defineComponent({
  name: "PublishableStatus",
  props: {
    item: { type: Object as PropType<Publishable>, required: true },
    size: { type: [String, Number], default: "small" },
  },
  render() {
    const props: any = { size: this.size };
    if (this.item.status === PublishableStatus.ACTIVE) {
      props.color = "primary";
      props.variant = "flat";
    } else if (this.item.status === PublishableStatus.ARCHIVED) {
      props.color = "purple";
      props.dark = true;
    } else {
      props.color = "teal";
    }

    return h(VChip, props, () => this.item.status);
  },
});
